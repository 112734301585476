import React from "react"
import { getCourseNavigation } from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"

import Column from "../../../../../components/column"
import Columns from "../../../../../components/columns"
import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Heading from "../../../../../components/heading"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import HalfAssedBox from "../../../../../components/half-assed-box"
import RotateLetter from "../../../../../components/rotate-letter"

const Principle = ({ letter, title, children, ad }) => {
  return (
    <HalfAssedBox>
      <Columns collapse={[true, false]}>
        <Column width="content">
          <RotateLetter>{letter}</RotateLetter>
        </Column>
        <Column>
          <Stack space={6}>
            <Stack space={3}>
              <Heading as="h3" level={4}>
                {title}
              </Heading>
              <Paragraph>{children}</Paragraph>
            </Stack>
          </Stack>
        </Column>
      </Columns>
    </HalfAssedBox>
  )
}

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "reiseerinnerung" })

  return (
    <LayoutCourse
      footer={
        <CourseFooterNext to="/kurse/reiseerinnerung/02-dichterwerkstatt/zusammenfassung" />
      }
      navigation={navigation}
    >
      <Seo title="Dein eigenes Video zum Gedicht" />
      <Stack>
        <Heading as="h2" level={2}>
          Dein eigenes Video zum Gedicht
        </Heading>
        <Paragraph>
          Ziehe selbst mit der Kamera oder dem Smartphone los. Suche nach Orten
          und Motiven, die du mit dem Gedicht verbindest und drehe ein Video mit
          einer eigenen sprecherischen Interpretation des Gedichts.
        </Paragraph>
        <Stack space={6}>
          <Principle letter="1" title="Orte und Motive finden">
            An erster Stelle des Videos steht eine gute Story. Lese Dir das
            Gedicht dazu noch einmal durch. Welche Stimmung transportiert das
            Gedicht und was stellst du Dir beim Lesen des Gedichtes vor?
            Überlege Dir, welche Orte oder Motive in deiner Gegend gut zum
            Inhalt und zur Stimmung des Gedichtes passen.
          </Principle>
          <Principle letter="2" title="Ablauf festlegen">
            Du hast einen Ort oder ein Motiv für dein Video gefunden? Überlege
            dir nun einen Ablauf und wo und wie die Szenen umgesetzt werden
            sollen. Übergänge und/ oder überraschende Geschehnisse können dein
            Video spannender machen. Denke auch daran, wie du dein Video
            beginnen willst und wie der Schluss aussehen soll. An dieser Stelle
            kannst du dir auch überlegen, ob du ein Stativ, einen Selfiestick,
            eine Taschenlampe oder anderes Zubehör benötigst.
          </Principle>
          <Principle letter="3" title="Dein Gedichtvortrag">
            Nun folgt deine Interpretation von Hölderlins Andenken. Du kannst
            das Gedicht selbst aufsagen und dich evtl. selbst filmen, oder du
            bittest deine Freunde oder Familie an deinem Video mitzuwirken.
            Überlege dir, wie das Gedicht aufgesagt oder aufgeführt und in das
            Video integriert werden könnte. Versuche die Stimmung, die du
            ausdrücken möchtest, in Sprache zu übersetzen. Lies dir das Gedicht
            noch einmal laut vor; spiele mit dem Tempo, Sprechpausen und der
            Lautstärke. Deine Interpretation des Gedichtes steht in
            Wechselwirkung mit der Szenerie, in der du filmst. Sie bilden
            gemeinsam die Gesamtwirkung und können sich gegenseitig
            unterstützen.
          </Principle>
          <Principle letter="4" title="Perspektiven und andere Tricks">
            Du hast alle Ideen für dein Video? Jetzt fehlt nur noch die
            Umsetzung. Traue dich kreativ zu sein und probiere neue Dinge aus.
            Du kannst zum Beispiel auch ungewöhnliche Formate und Perspektiven
            wählen (Hoch- oder Querformat, Vogel- oder Froschperspektive oder
            vielleicht aus Sicht eines Voyeurs?). Auch ein schwarz-weiß Film ist
            möglich.
          </Principle>
        </Stack>
      </Stack>
    </LayoutCourse>
  )
}

export default Page
